import { Button, Input } from 'antd'
import styled from 'styled-components/macro'

export const ChatFooterStyle = styled.div`
  height: 90px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
`

export const FormStyle = styled.form`
  display: flex;
  align-items: center;
`

export const HiddenFileInputStyle = styled.input`
  display: none;
`

export const InputAreaStyle = styled.div`
  padding: 0 5px;
  border-top: 1px solid #ededed; 
  border-bottom: 1px solid #ededed; 
  display: flex; 
  align-items: center;
  height: 52px;
  width: 100vw;
`

export const InputStyle = styled(Input)`
  padding-left: 2px;
  width: calc(100vw - 100px);
  border: none;
  box-shadow: none;

  &:focus {
    border: none;
    box-shadow: none;
  }
`

export const ButtonAttachStyle = styled(Button)`
  background: none;
  border: none;
  box-shadow: none;
  z-index: 10;

  .anticon {
    margin-top: 3px;
    color: #A3A3A3;
  }
`

export const SendButtonStyle = styled(Button)`
  border: none;

  .anticon {
    margin: 4px 0 0 3px;
  }
`
