import React from 'react'
import { Typography } from 'antd'
import { t } from 'i18next'

import { env } from 'common/env'

import * as Style from './UserAgreementWarning.style'

const { Text, Link } = Typography

export const UserAgreementWarning = () => (
  <Style.Wrapper
    direction="vertical"
    size={-5}
  >
    <Text style={{ color: '#BBB', fontSize: '13px' }}>
      {t('UserAgreementWarning.agreementFirst')}
    </Text>
    <Link
      size="small"
      style={{ fontSize: '13px' }}
      type="link"
      onClick={() => window.open(`${env.REACT_APP_LANDING_URL}/personaldata`, '_blank')}
    >
      {t('UserAgreementWarning.agreementSecond')}
    </Link>
  </Style.Wrapper>
)
