import React from 'react'
import { Button } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { observer } from 'mobx-react'

import { WebchatModel } from 'models'
import { detectUrl } from 'common/helpers'

import { MessageButtonsStyle } from './MessageButtonsStyle'

export const MessageButtons = observer(({ messageId = '', buttons = [], width }) => {
  const { color, replyAsButton, pressedButtons } = WebchatModel
  const isButtonsDirectionVertical = buttons.length > 2

  const openLink = (link) => window.open(link, '_blank')

  return (
    <MessageButtonsStyle
      color={color}
      gap={4}
      style={{ width }}
      vertical={isButtonsDirectionVertical}
      wrap="wrap"
    >
      {buttons.map((button, idx) => {
        const { message, value } = button
        const isLink = detectUrl(value)
        const disabled = !!pressedButtons?.find(btn => btn.messageId === messageId && btn.value === value)

        return (
          <Button
            key={`${messageId}-${value}`}
            data-testid={`chatButton-${idx}`}
            disabled={disabled}
            icon={isLink && <LinkOutlined />}
            size="large"
            type={isLink ? 'link' : 'default'}
            onClick={() => (isLink ? openLink(value) : replyAsButton(button, messageId))}
          >
            {message}
          </Button>
        )
      })}
    </MessageButtonsStyle>
  )
})
