import { Button, Flex } from 'antd'
import styled from 'styled-components'

export const ButtonStyle = styled(Button)`
  flex: 1;
  border-color: #d9d9d9;
  max-height: 40px;
  padding: 6px 10px;

  &:hover, &:focus {
    &:not(:disabled) {
      border-color: ${({ color }) => color || '#20C474'}!important;
      color: ${({ color }) => color || '#20C474'}!important;
    }
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
`

export const ListStyle = styled(Flex)`
  margin-top: 4px;
  max-width: 85%;
`
