import styled from 'styled-components/macro'

export const ChatBodyStyle = styled.div`
  margin-top: 65px;
  /* vvv duplicate for browsers without custom variables */
  height: calc(100vh - 146px); 
  height: calc((var(--vh, 1vh) * 100) - 146px);
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  ${({ background }) => `
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
  `}
`

export const MessagesStyle = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  padding: 15px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`
