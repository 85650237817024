import React from 'react'
import { string } from 'prop-types'

import { ChatVideoStyle } from './ChatVideoStyle'

/* eslint-disable jsx-a11y/media-has-caption */
export const ChatVideo = ({ url }) => (
  <ChatVideoStyle>
    <video controls height="auto" src={url} width="250px" />
  </ChatVideoStyle>
)

ChatVideo.propTypes = {
  url: string.isRequired,
}
