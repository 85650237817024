import { request } from 'models/ApiModel/ApiModel'

export const getChat = (token, params) => request({
  method: 'POST',
  url: `/sender/webapp/initialization/${token}`,
  params,
})

export const getChatSettings = (token) => request({
  method: 'GET',
  url: `/sender/webapp/settings/${token}`,
  params: {},
})

export const getHistory = ({ chatHash, ...params }, signal) => request({
  method: 'GET',
  url: '/sender/webapp/history',
  params,
  chatHash,
  signal,
})

export const sendMessage = ({ chatHash, ...params }) => request({
  method: 'POST',
  url: '/sender/webapp/message',
  params,
  chatHash,
})

export const uploadFile = ({ chatHash, ...params }) => {
  const formData = new FormData()
  formData.append('attachment', params.file)
  formData.append('projectId', `${params.projectId}`)
  formData.append('connectorId', `${params.connectorId}`)

  return request({
    method: 'POST',
    url: '/sender/webapp/attachment',
    params: formData,
    chatHash,
  })
}
