import React from 'react'
import { observer } from 'mobx-react'
import {
  Typography, Space,
} from 'antd'

import { WebchatModel } from 'models'
import { getColorByContrast } from 'common/helpers'

import { ChatHeaderStyle, LogoStyle } from './ChatHeaderStyle'

const { Text } = Typography

export const ChatHeader = observer(() => {
  const {
    logo, title, color,
  } = WebchatModel

  return (
    <ChatHeaderStyle backgroundColor={color}>
      <Space size={7}>
        {logo && <LogoStyle src={logo} />}
        <Space direction="vertical" size={-3}>
          {title && <Text disabled style={{ color: getColorByContrast(color) }}>{title}</Text>}
        </Space>
      </Space>
    </ChatHeaderStyle>
  )
})
