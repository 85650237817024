import axios from 'axios'
import { message } from 'antd'
import { t } from 'i18next'

import { env } from 'common/env'

export const axiosInstance = axios.create({
  baseURL: env.REACT_APP_BASE_URL,
  responseType: 'json',
})

export const request = async ({
  method,
  url,
  params = {},
  chatHash = '',
  signal,
}) => {
  const api = axiosInstance
  const headers = { 'Content-Type': 'application/json' }

  if (chatHash !== '') {
    headers.Authorization = `Bearer ${chatHash}`
  }

  if (params instanceof FormData) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  const queryParams = Object.entries(params)
    .filter(param => param[1] !== undefined)
    .map(param => `${param[0]}=${param[1]}`)
  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  const getUrl = url + queryString

  try {
    let response

    switch (method) {
      case 'GET':
        response = await api.get(getUrl, { headers, signal })
        break
      case 'POST':
        response = await api.post(url, params, { headers, signal })
        break
      default:
        response = await api.get(url, params, { headers, signal })
        break
    }

    return response.data
  } catch (error) {
    const { response } = error

    if ([400, 401, 403, 404, 500].includes(response?.status)) {
      message.error(t('ApiModel.errorOccurred'))
    }

    throw error
  }
}
