/* eslint-disable */
import React from 'react'
// import { saveAs } from 'file-saver'
// import { faPlay, faPause, faDownload } from '@fortawesome/free-solid-svg-icons'

import {
  PauseCircleFilled, PlayCircleOutlined, CloudDownloadOutlined, LoadingOutlined, PauseCircleOutlined,
} from '@ant-design/icons'
import { Typography } from 'antd'

import { ChatAudioStyle } from './ChatAudioStyle'

const { Text } = Typography

export class ChatAudio extends React.Component {
  state = {
    isPlaying: false,
    duration: null,
    formattedLength: null,
    currentTime: 0,
    formattedTime: null,
  }

  myInterval = null

  componentDidMount() {
    this.player.addEventListener('loadedmetadata', () => {
      this.duration()
    })
    this.player.addEventListener('ended', () => {
      this.player.currentTime = 0
      this.onPause()
    })
    this.player.addEventListener('pause', () => {
      this.onPause()
    })
  }

  onPlay() {
    document.querySelectorAll('audio').forEach((audio) => {
      audio.pause()
    })

    this.setState({ isPlaying: true }, () => this.setIntervals())
    this.duration()
    this.player.play()
  }

  onPause() {
    this.setState({ isPlaying: false }, () => clearInterval(this.myInterval))
    this.player && this.player.pause()
  }

  onDownload() {
    // saveAs(this.props.url, this.props.t('ChatAudio.audio'))
  }

  setIntervals() {
    this.myInterval = setInterval(() => {
      this.setState(prevState => ({ currentTime: this.currentTime() ? this.currentTime() : prevState.currentTime }))
    }, 10)
  }

  customTime = () => {
    const { url } = this.props
    const inSettings = this.props.inSettings ? '-inSettings' : '-chat'
    const timeline = document.getElementById(url.slice(-5) + inSettings)
    const newTime = parseFloat(timeline.value)
    this.player.currentTime = newTime
    this.setState({
      currentTime: newTime,
    })
  }

  currentTime() {
    const cur = this.player.currentTime

    this.setState({
      currentTime: cur,
    })
  }

  toTime(string = 0) {
    let minutes = Math.floor(string / 60)
    let seconds = Math.floor(string - (minutes * 60))

    if (minutes < 10) { minutes = `0${minutes}` }
    if (seconds < 10) { seconds = `0${seconds}` }

    return `${minutes}:${seconds}`
  }

  duration() {
    const dur = this.player?.duration || 0
    const formattedDuration = this.toTime(dur)

    this.setState({
      duration: dur,
      formattedDuration,
    })

    return dur
  }

  renderButton = () => {
    const { isLoaded, isPlaying } = this.state
    const { isInverted } = this.props

    const buttonStyle = {
      margin: '5px 8px 5px 0',
      fontSize: '26px',
      color: isInverted && 'white',
    }

    if (!isLoaded && isPlaying) {
      return (
        <LoadingOutlined
          onClick={() => this.onPause()}
          style={buttonStyle}
        />
      )
    }
    if (isLoaded && isPlaying) {
      return (
        <PauseCircleOutlined
          onClick={() => this.onPause()}
          style={buttonStyle}
        />
      )
    }
    if (!isPlaying) {
      return (
        <PlayCircleOutlined
          onClick={() => this.onPlay()}
          style={buttonStyle}
        />
      )
    }
  }

  render() { // TODO: refactor this component almost totally
    const { url, inSettings, isInverted } = this.props

    return (
      <ChatAudioStyle isInverted={isInverted}>
        <audio
          onCanPlayThrough={() => this.setState({ isLoaded: true })}
          ref={ref => this.player = ref}
        >
          <source
            src={url}
            type={url.split('.').pop() === 'mp3' ? 'audio/mp3' : 'audio/ogg'}
          />
        </audio>
        {this.renderButton()}
        <input
          className="timeline"
          id={url.slice(-5) + (inSettings ? '-inSettings' : '-chat')}
          type="range"
          step={0.1}
          min={0}
          max={this.state.duration}
          onChange={(e) => this.customTime(e)}
          value={this.player && this.player.currentTime}
        />
        <Text style={{
          margin: '5px 2px 5px 6px',
          color: isInverted && 'white',
        }}
        >
          {this.toTime(this.player?.currentTime) || this.state.formattedDuration}
        </Text>
        <CloudDownloadOutlined
          onClick={() => window.open(url)}
          style={{
            margin: '5px 0 5px 5px',
            fontSize: '20px',
            color: isInverted && 'white',
          }}
        />
      </ChatAudioStyle>
    )
  }
}
