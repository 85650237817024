import React from 'react'
import { ContactsOutlined } from '@ant-design/icons'
import { Space, Typography } from 'antd'
import {
  string, bool, objectOf, arrayOf,
} from 'prop-types'

import { formatPhone } from 'common/helpers'

import { ChatContactStyle } from './ChatContactStyle'

const { Text } = Typography

export const ChatContact = ({ contact: { name, phones, emails }, isInverted }) => (
  <ChatContactStyle>
    <Space align="center" size={8}>
      <ContactsOutlined style={{
        fontSize: '30px',
        color: isInverted && 'white',
      }}
      />
      <Space direction="vertical" size={5}>
        <Text disabled={isInverted} strong>{name}</Text>
        {phones?.map(({ phone }) => (<Text disabled={isInverted}>{formatPhone(phone)}</Text>))}
        {emails?.map(({ email }) => (<Text disabled={isInverted}>{email}</Text>))}
      </Space>
    </Space>
  </ChatContactStyle>
)

ChatContact.propTypes = {
  contact: objectOf({
    name: string,
    phones: arrayOf(string),
    emails: arrayOf(string),
  }),
  isInverted: bool,
}

ChatContact.defaultProps = {
  contact: objectOf({
    name: null,
    phones: null,
    emails: null,
  }),
  isInverted: false,
}
