import { t } from 'i18next'

import { urlDetector } from './regex'
import { isForRuMarket } from './constants'

export const getFileType = (extension = '') => {
  const typeExtensionPairs = {
    image: ['jpg', 'jpeg', 'png', 'gif'],
    video: ['mp4', 'avi', 'mov'],
    audio: ['mp3', 'ogg', 'wav'],
    file: ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'csv', 'rtf', 'txt', 'ppt', 'pptx'],
    unknown: [''],
  }

  return Object.entries(typeExtensionPairs)
    .find(pair => pair[1].includes(extension.toLowerCase()))[0]
}

export const camelizeKeys = (obj) => {
  const replaceAndUpperCase = (key) => key.replace(/[-_\s]+(.)?/g, (_, ch) => (ch ? ch.toUpperCase() : ''))

  const result = {}
  const keys = Object.keys(obj)

  keys.forEach(key => {
    result[replaceAndUpperCase(key)] = obj[key]
  })

  return result
}

export const parseFromLocalStorage = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name))
  } catch (error) {
    throw new Error(error)
  }
}

export const saveToLocalStorage = (name, data) => {
  if (data !== undefined) {
    localStorage.setItem(name, JSON.stringify(data))
  }
}

export const parseQueryString = (str) => {
  const objURL = {}

  str.replace(
    new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
    ($0, $1, $2, $3) => {
      objURL[$1] = $3
    },
  )

  return objURL
}

export const convertOurMarkdownToCommon = (text) => {
  let convertedText = text
  const bolds = text.match(/\*.+?\*/gi)

  if (bolds) {
    for (let i = 0; i < bolds.length; i++) { // TODO: refactor it to declarative
      const part = bolds[i].substring(1, bolds[i].length - 1)
      convertedText = convertedText.replace(bolds[i], `**${part}**`)
    }
  }

  return convertedText
}

export const formatPhone = (phone) => {
  const formattedPhone = `${phone}`
    .replace(/\+|\D| /g, '')
    .match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2,3})$/)
  // Форматирует и 11, и 12-значные номера, но нужно добавить кейс оформления 12-значных

  const [, a, b, c, d, e] = formattedPhone || []
  if (formattedPhone) {
    return `+${a} (${b}) ${c}-${d}-${e}`
  }

  if (phone && phone[0] !== '+') {
    return `+${phone}`
  }

  return phone
}

export const detectUrl = (text) => urlDetector.test(text)

export const getColorByContrast = (hexcolor = '') => {
  const color = hexcolor?.replace('#', '') ?? ''
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq >= 180 ? '#000000' : '#ffffff'
}

export const getHrmName = () => (isForRuMarket ? t('App.hrmka') : t('App.hrmessenger'))
