import styled from 'styled-components'

export const ChatMessageStyle = styled.div`
  margin: 0 5px 10px 5px;
  display: flex;
  flex-direction: column;
`

export const MessageLoadingStyle = styled.div`
  margin: 0 5px 10px 5px;
  align-self: flex-start;
  background: #f2f2f2;
  float: left;
  border-radius: 0 10px 10px 10px;
  padding: 10px;
`
