import React from 'react'
import {
  CloudDownloadOutlined, FileOutlined,
} from '@ant-design/icons'
import { Space, Typography } from 'antd'
import { string, bool } from 'prop-types'

import { ChatDocumentStyle } from './ChatDocumentStyle'

const { Text } = Typography

export const ChatDocument = ({ url, isInverted }) => (
  <ChatDocumentStyle>
    <Space align="center" size={8}>
      <FileOutlined style={{
        margin: '0 -3px 6px 0',
        fontSize: '13px',
        color: isInverted && 'white',
      }}
      />
      <Text disabled={isInverted}>{url.split('/').pop()}</Text>
      <CloudDownloadOutlined
        style={{
          margin: '4px 0 0 2px',
          fontSize: '20px',
          color: isInverted && 'white',
        }}
        onClick={() => window.open(url)}
      />
    </Space>
  </ChatDocumentStyle>
)

ChatDocument.propTypes = {
  url: string.isRequired,
  isInverted: bool,
}

ChatDocument.defaultProps = {
  isInverted: false,
}
