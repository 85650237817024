import { Avatar } from 'antd'
import styled from 'styled-components/macro'

export const ChatHeaderStyle = styled.div`
  height: 65px;
  background: ${({ backgroundColor }) => backgroundColor || '#20C474'}; 
  display: flex;
  align-items: center;
  padding-left: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
`

export const LogoStyle = styled(Avatar)`
  width: 45px;
  height: 45px;
`
