import styled from 'styled-components'
import { Space, Typography } from 'antd'

import { getColorByContrast } from 'common/helpers'

export const MessageContentsStyle = styled(Space)`
  white-space: break-spaces;
  padding: 10px;
  display: inline-block;
  max-width: 85%;
  ${({ state, $backgroundColor }) => (state === 'received'
    ? `
    align-self: flex-start;
    background: #f2f2f2;
    float: left;
    border-radius: 0 10px 10px 10px;`
    : `
    align-self: flex-end;
    background: ${$backgroundColor};
    float: right;
    border-radius: 10px 10px 0 10px;
    `
  )}

  img, video {
    border-radius: 4px;
  }

  video:focus {
    outline: none;
  }

  /* following styles are for markdown elements inside message */
  p:last-child, ol:last-child { margin-bottom: 0px; }
  a { 
    ${({ state, backgroundColor }) => (state === 'sent' && `color: ${getColorByContrast(backgroundColor)}`)} 
  }
`

export const MessageTextStyle = styled(Typography.Text)`
  && {
    > p {
      margin: 0;
    }

    .ant-typography a:hover, .ant-typography a:focus {
      color: ${({ disabled }) => disabled && 'inherit'};
    }
  }   
`
