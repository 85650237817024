import styled from 'styled-components/macro'

export const ChatAudioStyle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    > .timeline {
        -webkit-appearance: none;
        background: transparent;
       outline: none;
    }
    
    .timeline::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        animate: 0.2s;
        background: ${({ isInverted }) => (isInverted ? 'rgb(255, 255, 255, 0.5)' : 'rgb(0, 0, 0, 0.2)')};
      }

    .timeline::-webkit-slider-thumb {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background: ${({ isInverted }) => (isInverted ? 'white' : 'black')};
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -3px;
        border: none;
     }
`
