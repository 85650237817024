import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Space, Typography } from 'antd'
import { PaperClipOutlined, SendOutlined } from '@ant-design/icons'
import { t } from 'i18next'

import { env } from 'common/env'
import { WebchatModel } from 'models'
import { getColorByContrast, getHrmName, getFileType } from 'common/helpers'

import {
  ButtonAttachStyle, ChatFooterStyle, FormStyle, HiddenFileInputStyle, InputAreaStyle, InputStyle, SendButtonStyle,
} from './ChatFooterStyle'

const { Link, Text } = Typography

export const ChatFooter = observer(() => {
  const [message, setMessage] = useState('')
  const fileInputRef = useRef()
  const inputRef = useRef()

  useEffect(() => {
    window.addEventListener('touchmove', blurInput)

    return () => window.removeEventListener('touchmove', blurInput)
  }, [])

  const blurInput = () => {
    inputRef.current.blur()
  }

  const onSubmit = (e) => {
    e.preventDefault()
    inputRef.current.focus()

    if (/\S/.test(message)) {
      WebchatModel.sendMessage(message)
      setMessage('')
    }
  }

  const sendFile = (e) => {
    const file = e.target.files[0]
    const fileExtension = file.name.split('.').pop()
    const fileType = getFileType(fileExtension)

    if (fileType !== 'unknown') {
      WebchatModel.uploadFile(file)
        .then(attachmentUrl => WebchatModel.sendFile(attachmentUrl, fileType)) // TODO: finalize when backend
        .finally(() => { e.target.value = null })
    }
  }

  const renderCopyright = () => (
    <Space
      align="center"
      size={3}
      style={{ width: '100%', justifyContent: 'center', padding: 7 }}
    >
      <Text
        style={{ color: '#BBB', fontSize: '13px' }}
      >
        {'🚀 '}
        {t('ChatFooter.copyright')}
      </Text>
      <Link
        size="small"
        style={{ textDecoration: 'none', color: '#BBB', fontSize: '13px' }}
        onClick={() => window.open(env.REACT_APP_LANDING_URL, '_blank')}
      >
        {getHrmName()}
      </Link>
    </Space>
  )

  const {
    color, isSettingsLoaded, isTyping, messages,
  } = WebchatModel

  const isSendDisabled = () => !isSettingsLoaded || isTyping || !messages || !message // TODO: /\S/.test(message)
  const isAttachDisabled = () => !isSettingsLoaded || isTyping || !messages

  return (
    <ChatFooterStyle>
      <Space direction="vertical" size={0}>
        <InputAreaStyle>
          <ButtonAttachStyle
            disabled={isAttachDisabled()}
            icon={<PaperClipOutlined style={{ fontSize: 22 }} />}
            loading={WebchatModel.isAttachmentUploading}
            shape="circle"
            size="large"
            onClick={() => fileInputRef.current.click()}
          />
          <FormStyle onSubmit={(e) => onSubmit(e)}>
            <InputStyle
              ref={inputRef}
              data-testid="messageInputPlaceholder"
              placeholder={t('ChatFooter.inputPlaceholder')}
              size="large"
              value={message}
              onChange={({ target }) => setMessage(target.value)}
            />
            <SendButtonStyle
              disabled={isSendDisabled()}
              htmlType="submit"
              icon={<SendOutlined style={{ color: isSendDisabled() ? 'white' : getColorByContrast(color) }} />}
              shape="circle"
              size="large"
              style={{ background: !isSendDisabled() && color }}
            />
          </FormStyle>
        </InputAreaStyle>
        {renderCopyright()}
      </Space>
      <HiddenFileInputStyle
        ref={fileInputRef}
        // accept={item.acceptedFormats} // TODO: add excepted file formats
        type="file"
        onChange={sendFile}
      />
    </ChatFooterStyle>
  )
})
