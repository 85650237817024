import React from 'react'
import { observer } from 'mobx-react'

import { WebchatModel } from 'models'

import * as Style from './MessageListStyle'

export const MessageList = observer(({ messageId = '', items = [], width }) => {
  const { color, replyAsButton } = WebchatModel

  const handleClick = ({ id, title }) => {
    const button = { value: id, message: title }
    replyAsButton(button, messageId)
  }

  return (
    <Style.ListStyle gap={4} style={{ width }} vertical={items.length > 2}>
      {items.map(item => (
        <Style.ButtonStyle
          key={item.id}
          color={color}
          size="large"
          onClick={() => handleClick(item)}
        >
          {item.title}
        </Style.ButtonStyle>
      ))}
    </Style.ListStyle>
  )
})
