/* eslint-disable max-len */

export const appendExternalScripts = () => {
  appendYandexMetrikaScript()
}

const appendYandexMetrikaScript = () => {
  const yandexMetrikaFunction = '(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};  m[i].l=1*new Date();  k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})  (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");  ym(90985926, "init", {      clickmap:true,      trackLinks:true,      accurateTrackBounce:true,      webvisor:true  });'
  const yandexMetrikaElement = '<div>  <img src="https://mc.yandex.ru/watch/90985926" style="position:absolute; left:-9999px;" alt="" />  </div>'

  const script = document.createElement('script')
  script.innerHTML = yandexMetrikaFunction

  const noScript = document.createElement('noscript')
  noScript.innerHTML = yandexMetrikaElement

  document.body.append(script)
  document.body.append(noScript)
}
