import { Space } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled(Space)`
  margin: 0 auto 10px auto;
  texta-align: center;
  display: block;
  background: white;
  padding: 4px 16px;
  border-radius: 8px;
`
