import styled from 'styled-components/macro'
import { Flex } from 'antd'

export const MessageButtonsStyle = styled(Flex)`
  .ant-btn {
    flex: 1;
    margin-top: 4px;
    border-color: #d9d9d9;
    max-width: 100%;
    max-height: 40px;
    padding: 6px 10px;

    &:disabled {
      color: rgba(0, 0, 0, 0.4);
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }
  }

  .ant-btn:hover, .ant-btn:focus {
    &:not(:disabled) {
      border-color: ${({ color }) => color || '#20C474'};
      color: ${({ color }) => color || '#20C474'};
    }
  }
`
