import React, { useState } from 'react'

import { ChatLoading } from 'components/atoms'

import { ChatMessageContents, MessageButtons, MessageList } from './components'
import { ChatMessageStyle, MessageLoadingStyle } from './ChatMessageStyle'

export const ChatMessage = ({
  color, state, message, messageId, isTyping,
}) => {
  const [width, setWidth] = useState(0)

  if (isTyping) {
    return (
      <MessageLoadingStyle>
        <ChatLoading />
      </MessageLoadingStyle>
    )
  }

  return (
    <ChatMessageStyle>
      <ChatMessageContents
        color={color}
        message={message}
        messageId={messageId}
        state={state}
        onSetWidth={setWidth}
      />
      {message?.buttons && <MessageButtons buttons={message.buttons} messageId={messageId} width={width} />}
      {message?.list && <MessageList items={message.list.items} messageId={messageId} width={width} />}
    </ChatMessageStyle>
  )
}

ChatMessage.defaultProps = {
  color: '#20C474',
}
