import React from 'react'
import * as ReactDOMClient from 'react-dom/client'

import { App } from './App'
import './index.css'
import './locale/i18n'
import { appendExternalScripts } from './common/externalServices'

const { NODE_ENV, REACT_APP_BASE_URL } = process.env
let raiseMissingEnvVarException = false

if (!REACT_APP_BASE_URL) { raiseMissingEnvVarException = true }

if (raiseMissingEnvVarException) {
  throw new Error('One of the essential variables missing in .env file. Check .env.example')
}

if (NODE_ENV === 'production') {
  appendExternalScripts()
}

const root = ReactDOMClient.createRoot(document.getElementById('root'))

root.render(<App />)
