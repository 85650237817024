import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { ChatHeader, ChatFooter, ChatBody } from 'components/molecules'
import { WebchatModel } from 'models'

import { WebchatStyle } from './WebchatStyle'

// TODO: antd Space size, Text color: white,...

export const Webchat = observer(() => {
  useEffect(() => {
    WebchatModel.loadChat()
    window.addEventListener('touchmove', preventZoom, { passive: false })
    window.addEventListener('resize', updateVerticalHeight)

    return () => {
      window.removeEventListener('touchmove', preventZoom, { passive: false })
      window.removeEventListener('resize', updateVerticalHeight)
    }
  }, [])

  const preventZoom = (e) => {
    if (![1, undefined].includes(e.scale)) { e.preventDefault() } // undefined is for old android browsers
  }

  const updateVerticalHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const { isSettingsLoaded, messages } = WebchatModel

  return (
    <WebchatStyle>
      {isSettingsLoaded && <ChatHeader />}
      <Spin
        indicator={(
          <LoadingOutlined
            spin
            style={{
              margin: '248px 0 0 -30px', // TODO: remove centering hardcode (?)
              fontSize: '60px',
              color: '#DDD',
            }}
          />
        )}
        size="large"
        spinning={!messages || !isSettingsLoaded}
      >
        {(messages && isSettingsLoaded) ? <ChatBody /> : null}
      </Spin>
      <ChatFooter />
    </WebchatStyle>
  )
})
