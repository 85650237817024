import React, { useEffect, useRef } from 'react'
import { Image } from 'antd'
import ReactMarkdown from 'react-markdown'
import { object, oneOf } from 'prop-types'
import remarkGfm from 'remark-gfm'

import { getColorByContrast, convertOurMarkdownToCommon } from 'common/helpers'

import { MessageContentsStyle, MessageTextStyle } from './ChatMessageContentsStyle'
import {
  ChatAudio, ChatContact, ChatDocument, ChatVideo,
} from './components'

export const ChatMessageContents = ({
  message, state, messageId, color, onSetWidth,
}) => {
  const messageRef = useRef(null)

  useEffect(() => {
    if (messageRef.current) {
      onSetWidth && onSetWidth(messageRef.current.clientWidth)
    }
  }, [onSetWidth])

  if (!message) { return null }

  const {
    contact, files, file, text, list,
  } = message

  const renderedMessageContents = [null]

  const isInverted = state === 'sent'

  const pushFileToContent = ({ type, url }) => {
    const key = `${messageId}-${url}`
    switch (type) {
      case 'image':
        renderedMessageContents.push(<Image key={key} src={url} width="250px" />)
        break
      case 'video':
        renderedMessageContents.push(<ChatVideo key={key} url={url} />)
        break
      case 'document':
        renderedMessageContents.push(<ChatDocument key={key} isInverted={isInverted} url={url} />)
        break
      case 'audio':
      case 'voice':
        renderedMessageContents.push(<ChatAudio key={key} isInverted={isInverted} url={url} />)
        break
      default:
    }
  }

  if (contact) {
    const key = `${messageId}-${JSON.stringify(contact)}`
    renderedMessageContents.push(
      <ChatContact key={key} contact={contact} isInverted={isInverted} />,
    )
  }

  if (file) { pushFileToContent(file) }

  if (files) {
    files.forEach((item) => { pushFileToContent(item) })
  }

  if (text) {
    const textColor = isInverted ? getColorByContrast(color) : 'rgba(0, 0, 0, 0.88)'
    const listTitle = list ? `\n${list?.title}:` : ''
    renderedMessageContents.push(
      <MessageTextStyle
        key={`${messageId}-${text}`}
        disabled={isInverted}
        style={{ color: textColor }}
      >
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {/* TODO: vvv Remove convertOurMarkdownToCommon() if we will reorganize our markdown to common */}
          {convertOurMarkdownToCommon(text)}
        </ReactMarkdown>
        {listTitle}
      </MessageTextStyle>,
    )
  }

  return (
    <MessageContentsStyle
      ref={messageRef}
      $backgroundColor={color}
      direction="vertical"
      state={state}
    >
      {renderedMessageContents}
    </MessageContentsStyle>
  )
}

ChatMessageContents.propTypes = {
  message: object.isRequired, // eslint-disable-line react/forbid-prop-types
  state: oneOf(['sent', 'received']).isRequired,
}
