import i18next from 'i18next'

import { parseQueryString } from 'common/helpers'

import en from './en.json'
import ru from './ru.json'

const resources = {
  en: { translation: en },
  ru: { translation: ru },
}

const isLangEn = () => {
  const queryString = parseQueryString(window.location.search)

  return queryString.lang === 'en'
}

i18next.init({
  resources,
  lng: isLangEn() ? 'en' : 'ru',
  fallbackLng: 'ru',

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
})
