import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'

import { WebchatModel } from 'models'

import { ChatMessage, UserAgreementWarning } from '..'

import { ChatBodyStyle, MessagesStyle } from './ChatBodyStyle'

export const ChatBody = observer(() => {
  const {
    color, isTyping, messages, tempSentMessage, background,
  } = WebchatModel
  const messagesRef = useRef(null)

  useEffect(() => {
    const messagesElement = messagesRef.current

    if (messagesElement || tempSentMessage) {
      messagesElement.scroll({
        top: messagesElement.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [messages.length, tempSentMessage])

  const renderMessages = (_messages) => {
    const renderedMessages = _messages.map(({ message, messageId, state }) => (
      <ChatMessage
        key={messageId}
        color={color}
        message={message}
        messageId={messageId}
        state={state}
      />
    ))

    return renderedMessages
  }

  return (
    <ChatBodyStyle background={background}>
      <MessagesStyle ref={messagesRef} id="chatMessages">
        <UserAgreementWarning />
        {messages && renderMessages(messages)}
        {tempSentMessage && renderMessages([tempSentMessage])}
        {isTyping && (
          <ChatMessage isTyping={isTyping} />
        )}
      </MessagesStyle>
    </ChatBodyStyle>
  )
})
