/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { ConfigProvider } from 'antd'

import { theme } from 'common/theme'
import { getHrmName } from 'common/helpers'

import { Webchat } from './components/pages'

const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

// safari touch lag workaround
document.body.addEventListener('touchstart', () => { })
document.body.addEventListener('touchend', () => { })
document.body.addEventListener('touchcancel', () => { })
document.body.addEventListener('scroll', () => { })
document.body.addEventListener('touchmove', () => { })

export function App() {
  document.title = getHrmName()

  return (
    <ConfigProvider theme={theme}>
      <Webchat />
    </ConfigProvider>
  )
}
