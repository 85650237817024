import { configure, makeAutoObservable } from 'mobx'
import { message as antdAlert } from 'antd'
import { t } from 'i18next'

import {
  parseFromLocalStorage, parseQueryString, saveToLocalStorage,
} from 'common/helpers'

import {
  getChat, getChatSettings, getHistory, sendMessage, uploadFile,
} from './WebchatApi'
import { normalizeMessages } from './WebchatHelpers'

configure({ enforceActions: 'observed' })

export class WebchatModel { // TODO: переписать практически полностью
  constructor() {
    Object.freeze(this.initialState)
    this.init()

    makeAutoObservable(this)
  }

  initialState = {
    messages: undefined,
    isTyping: false, // TODO: consider name changing for waitingForBotAnswer or smth
    tempSentMessage: null,
    isAttachmentUploading: false,
    logo: undefined,
    title: undefined,
    color: undefined,
    background: undefined,
    isSettingsLoaded: false,
    pressedButtons: [],
    requestsWithoutNewBotAnswer: 0,
    historyAbortController: null,
    intervalId: null,
  }

  loadChat() {
    const { location } = window
    const queryString = parseQueryString(location.search)

    const tokenFromUrl = queryString.token
    const respondentIdFromUrl = queryString.respondentId
    if (!tokenFromUrl) {
      antdAlert.error(t('WebchatModel.noChat'))
      this.set('messages', [])
      this.set('isSettingsLoaded', true)
    } else {
      this.getChatSettings(tokenFromUrl)

      const connectorId = parseFromLocalStorage('connectorId')
      const hubId = parseFromLocalStorage('hubId')

      const shouldCreateNewSession = !connectorId || hubId !== tokenFromUrl || location.hash === '#clear'
      const shouldGetChat = !!respondentIdFromUrl

      const removeClearTagFromUrl = () => window.history.replaceState(null, null, ' ')

      const removeRespondentIdFromUrl = () => {
        const url = new URL(location.href)
        url.searchParams.delete('respondentId')
        window.history.replaceState(null, '', url)
      }

      switch (true) {
        // Нам нужен этот кейс на случай, если респондент переходит по особой ссылке из СМС
        case shouldGetChat:
          this.getChat(tokenFromUrl, respondentIdFromUrl)
            .then(() => removeRespondentIdFromUrl())
          removeClearTagFromUrl()
          break
        case shouldCreateNewSession:
          this.createChat(tokenFromUrl)
          removeClearTagFromUrl()
          break
        default:
          this.getMessagesHistory(connectorId)
      }
    }
  }

  createChat(token) {
    return getChat(token)
      .then(({ data }) => { this.handleChatInitialize(token, data) })
  }

  getChat(token, respondentId) {
    return getChat(token, { respondentId })
      .then(({ data }) => { this.handleChatInitialize(token, data) })
  }

  handleChatInitialize = (token, { connectorId, projectId, chatHash }) => {
    saveToLocalStorage('connectorId', connectorId)
    saveToLocalStorage('hubId', token)
    saveToLocalStorage('projectId', projectId)
    saveToLocalStorage('chatHash', chatHash)

    this.getMessagesHistory(connectorId)
  }

  getChatSettings(token) {
    return getChatSettings(token)
      .then(({ data }) => {
        const {
          logo, title, color, background,
        } = data

        this.set('logo', logo)
        this.set('color', color ?? '#20C474')
        this.set('background', background ?? undefined)

        this.set('title', title)
        this.set('isSettingsLoaded', true)

        window.parent.postMessage({ message: 'chatSettingsLoaded', value: token }, '*')
      })
  }

  getMessagesHistory = (connectorId) => {
    const projectId = parseFromLocalStorage('projectId')
    const chatHash = parseFromLocalStorage('chatHash')

    this.set('requestsWithoutNewBotAnswer', 0)

    const requestHistory = () => {
      this.historyAbortController = new AbortController()

      getHistory({ chatHash, projectId, connectorId }, this.historyAbortController.signal)
        .then(({ data }) => {
          const convertedMessages = data.messages.map(normalizeMessages)
          this.setMessagesHistory(convertedMessages)
          this.set('pressedButtons', parseFromLocalStorage('pressedButtons') || [])

          const lastHistoryMessage = convertedMessages[convertedMessages.length - 1]
          const lastMessage = this.messages[this.messages.length - 1]

          // TODO: replace this shitty pseudo-websockets with normal logic
          if (lastHistoryMessage?.state === 'received') {
            this.set('isTyping', false)
          }

          if (lastHistoryMessage?.state !== 'received' || lastHistoryMessage?.text === lastMessage?.text) {
            this.set('requestsWithoutNewBotAnswer', this.requestsWithoutNewBotAnswer + 1)
          }

          if (this.requestsWithoutNewBotAnswer > 8) {
            this.set('isTyping', false)
            this.set('requestsWithoutNewBotAnswer', 0)
          }
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            clearInterval(this.intervalId)
          }
        })
    }

    requestHistory()
    this.intervalId = setInterval(() => requestHistory(), 3000)
  }

  setMessagesHistory(messages) {
    this.set('tempSentMessage', null) // Убираем временно добавленное сообщение юзера
    this.set('messages', [...messages])
  }

  sendMessage = async (message) => {
    const tempSentMessage = { message: { text: message }, state: 'sent' }

    const content = { type: 'text', body: message }

    await this.sendFullMessage(content, tempSentMessage)
  }

  replyAsButton = async (button, messageId) => {
    const { value } = button
    this.set('pressedButtons', [...this.pressedButtons, { messageId, value }])
    saveToLocalStorage('pressedButtons', this.pressedButtons)

    const tempSentMessage = { message: { text: button.message }, state: 'sent' }

    const content = { type: 'button', params: button }

    await this.sendFullMessage(content, tempSentMessage)
  }

  uploadFile = (file) => {
    const connectorId = parseFromLocalStorage('connectorId')
    const projectId = parseFromLocalStorage('projectId')
    const chatHash = parseFromLocalStorage('chatHash')
    this.set('isAttachmentUploading', true)

    return uploadFile({
      chatHash,
      projectId,
      connectorId,
      file,
    })
      .then(({ data }) => data.path)
      .finally(() => this.set('isAttachmentUploading', false))
  }

  sendFile = async (url, type) => {
    const tempSentMessage = {
      message: { file: { type, url } },
      state: 'sent',
    }

    this.set('tempSentMessage', tempSentMessage)

    const content = { type, body: url }

    await this.sendFullMessage(content)
  }

  sendFullMessage = async (content, tempSentMessage) => {
    const projectId = parseFromLocalStorage('projectId')
    const connectorId = parseFromLocalStorage('connectorId')
    const chatHash = parseFromLocalStorage('chatHash')

    tempSentMessage && this.set('tempSentMessage', tempSentMessage)
    this.set('isTyping', true)
    this.set('requestsWithoutNewBotAnswer', 0)

    // отменяем запрос истории до и после отправки сообщения, для избежания случаев, когда
    // history пришли до отправки, и отправленное сообщение исчезло на несколько секунд
    this.historyAbortController && this.historyAbortController.abort()

    await sendMessage({
      chatHash,
      projectId,
      connectorId,
      content,
    })

    this.historyAbortController && this.historyAbortController.abort()
  }

  hasProperty = key => Object.prototype.hasOwnProperty.call(this, key)

  set(item, value) {
    if (this.hasProperty(item)) {
      this[item] = value
    }
  }

  init() {
    for (const kvp of Object.entries(this.initialState)) {
      const [key, value] = kvp

      this[key] = value
    }
  }
}

export default new WebchatModel()
